<template>
  <div class="container">
    <div class="con">
      <div class="between">
        <div class="title">
          <h2>游戏名字</h2>
          <input
            v-model="form.title"
            type="text"
            placeholder="请输入游戏名字，以便后期方便查找"
          />
        </div>
        <button class="btn" @click="saveConfig">保存游戏</button>
      </div>
      <div class="type-wrap">
        <h2 class="type-title">游戏分类</h2>
        <el-select
          v-model="cate"
          filterable
          allow-create
          default-first-option
          placeholder="请选择"
        >
          <el-option
            v-for="item in cateList"
            :key="item.cate_id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="game4-wrap">
        <div class="game-item" v-for="(item, i) in 10" :key="i">
          <h3 class="game-index">第{{ i + 1 }}题</h3>
          <div class="game-ques">
            <label class="ques-title">问题:</label>
            <input
              type="text"
              v-model="quesList[i]"
              class="ques-input"
              placeholder="请输入"
            />
          </div>
          <div class="game-ques">
            <label class="ques-title">答案:</label>
            <div class="ans-input" :id="'editorElem' + (i + 1)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
export default {
  data() {
    return {
      preSrc: config.src,
      gameId: "", //游戏id
      form: {
        title: "",
      },
      quesList: [],
      ansList: [],
      cateList: [],
      cate: "",
    };
  },
  created() {
    let _self = this;
    _self.gameTypeList();
  },
  mounted() {
    for (let i = 0; i < 10; i++) {
      let editor = new E("#editorElem" + (i + 1));
      editor.config.onchange = (html) => {
        this.ansList[i] = html;
      };
      editor.config.menus = [
        "bold", // 粗体
        "foreColor", // 文字颜色
      ];
      editor.config.showFullScreen = false;
      //过滤掉复制文本中自带的样式,默认开启
      editor.config.pasteFilterStyle = false;
      editor.create();
    }
  },
  methods: {
    // 获取游戏分类
    gameTypeList() {
      let _self = this;
      _self.axios.post(config.url.getCate).then((res) => {
        if (res.code == 200) {
          _self.cateList = res.data.length > 0 ? res.data : [];
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
        }
      });
    },
    // 提交
    saveConfig() {
      let _self = this;
      let title = _self.form.title;
      let cate = _self.cate;
      let quesList = _self.quesList;
      let ansList = _self.ansList;
      let newArr = [];
      quesList.forEach((item, i) => {
        if (item.trim() != "" && ansList[i] && ansList[i].trim() != "") {
          newArr.push({
            ques: item,
            ans: ansList[i],
            status: 1,
          });
        }
      });
      if (!title) {
        _self.showTip("error", "请输入游戏标题");
        return;
      } else if (!cate) {
        _self.showTip("error", "请选择游戏分类");
        return;
      } else if (newArr.length == 0) {
        _self.showTip("error", "请至少填写一个问题");
        return;
      } else {
        let params = {
          game_id: 4,
          config: JSON.stringify(newArr),
          title: title,
          cate_name: cate,
        };
        _self.axios
          .post(config.url.saveConfig, _self.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              _self.form = {
                title: "",
              };
              _self.cate = "";
              _self.quesList = []
              _self.ansList = []
              _self.showTip("success", res.msg);
              _self.$router.replace("/profile/game");
            } else if (res.code != 200 && res.code != 401) {
              _self.showTip("error", res.msg);
            }
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #fff;
  .upload-box {
    input,
    i {
      cursor: pointer;
    }
  }
}
.con {
  margin: 60px auto 0;
  .title {
    width: 500px;
    h2 {
      font-size: 30px;
      margin-bottom: 20px;
    }
    input {
      width: 100%;
      font-size: 18px;
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid #bebebe;
      box-sizing: border-box;
      outline: none;
      margin-bottom: 30px;
    }
    input::placeholder {
      color: #bebebe;
    }
    input:focus {
      border: none;
      border-bottom: 1px solid $color-main;
    }
  }
  .upload-box {
    i {
      width: 100%;
      height: 100%;
      font-size: 22px;
      color: #fff;
      background: $color-main;
      border-radius: 50%;
      padding: 10px;
      box-sizing: border-box;
      position: relative;
      top: -3px;
      left: 143px;
      cursor: pointer;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    img {
      position: absolute;
      width: 150px;
      height: 150px;
      top: 15px;
      object-fit: contain;
    }
  }
  .game {
    margin-bottom: 60px;
  }
  .type-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .type-title {
      font-size: 20px;
      margin-right: 10px;
    }
    .el-input__inner {
      border: 1px solid #bebebe !important;
    }
  }
  .game4-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .game-item {
      width: 48%;
      margin: 10px 0;
      .game-ques {
        display: flex;
        margin: 10px 0;
        .ques-title {
          width: 48px;
          margin-right: 2px;
          flex-shrink: 0;
          font-size: 14px;
          line-height: 36px;
        }
        .ques-input {
          width: 100%;
          outline: none;
          background: transparent;
          border: 1px solid #bebebe;
          padding: 6px 12px;
          font-size: 14px;
        }
        .ans-input {
          width: 100%;
        }
        input:focus {
          border: 1px solid $color-main;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.w-e-text-container {
  height: 48px !important;
  border: 1px solid #bebebe !important;
  .w-e-text {
    min-height: 48px !important;
  }
}
.w-e-toolbar {
  border: 1px solid #bebebe !important;
  border-bottom: none !important;
}
</style>
